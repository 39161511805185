<template>
    <div class="animated fadeIn">
        <wit-tabbed-form
            :formId="formId"
            :steps="steps"
            headerLogo="logotypes/rtbhouse_36.svg"
            headerText="Setup Your RTB House Collect"
            @continue="continueForm"
            @reset="resetForm"
            @input="onFormInput"
            @finish="createCollect"
            ref="tabbedForm"
            :update="updateCollect"
            :details="isDetails"
            :dirty="dirty"
            :fetched="fetched"
            :update-redirect="updateRedirect"
        >
            <template v-slot:step-1-feedback>
                <b-row>
                    <b-col>
                        <Feedback v-if="rtbError" :state="rtbError.state" :invalid="rtbError.message"></Feedback>
                    </b-col>
                </b-row>
            </template>
        </wit-tabbed-form>
        <Loading :loading="$store.state.loading.processing" :text="$store.state.loading.text"></Loading>
    </div>
</template>

<script>
import {validationMixin} from 'vuelidate'
import {required} from 'vuelidate/lib/validators'

import {mapGetters} from 'vuex'

import Feedback from '@/components/Feedback.vue'
import Loading from '@/components/loading.vue'
import WitTabbedForm from '@/components/WitTabbedForm.vue'

import {collectMixin} from '@/mixins/collectMixin'
import {formMixin} from '@/mixins/formMixin'
import {filterDirty} from '@/shared/filterDirty.js'

export default {
    data() {
        return {
            collectForm: {
                name: '',
                tokenId: '',
                countConvention: null,
                advertisers: [],
            },
            workflowForm: {
                historicalDateRange: 30,
            },
            currentCollectForm: {},
            advertisers: [],
            countConventionOptions: [
                {
                    id: 'ATTRIBUTED',
                    label: 'Attributed',
                },
                {
                    id: 'ALL_POST_CLICK',
                    label: 'Post click',
                },
                {
                    id: 'POST_VIEW',
                    label: 'Post view',
                },
            ],
            rtbError: {
                state: true,
                message: null,
            },
        }
    },
    components: {
        Feedback,
        Loading,
        WitTabbedForm,
    },
    mixins: [collectMixin, formMixin, validationMixin],
    validations: {
        collectForm: {
            name: {required},
            tokenId: {required},
            countConvention: {required},
            advertisers: {required},
        },
        workflowForm: {
            historicalDateRange: {},
        },
    },
    computed: {
        ...mapGetters({
            activeProject: 'project/active',
            tokens: 'token/rtbTokens',
        }),
        formId() {
            return this.isDetails ? 'rtb-create-form' : 'rtb-details-form'
        },
        steps() {
            return [
                {
                    name: 'RTB House Settings',
                    invalid: this.$v.collectForm.$invalid,
                    inputs: [
                        {
                            name: 'name',
                            value: this.$v.collectForm.name.$model,
                            model: this.$v.collectForm.name,
                            type: 'text',
                            inputLabel: 'Collect name',
                            placeholder: 'Enter your collect name',
                            invalid: 'This field is required',
                            valid: 'Name is valid',
                        },
                        {
                            name: 'token',
                            value: this.$v.collectForm.tokenId.$model,
                            model: this.$v.collectForm.tokenId,
                            type: 'rtb-token',
                            inputLabel: 'Select RTB House token',
                            disabled: this.isDetails,
                            onInput: this.onTokenInput,
                        },
                        {
                            name: 'count-convention',
                            value: this.$v.collectForm.countConvention.$model,
                            model: this.$v.collectForm.countConvention,
                            type: 'select',
                            inputLabel: 'Count convention',
                            label: 'label',
                            options: this.countConventionOptions,
                            placeholder: 'Select count convention',
                            invalid: 'This field is required',
                            valid: 'Count convention is valid',
                        },
                        {
                            name: 'advertisers',
                            value: this.$v.collectForm.advertisers.$model,
                            model: this.$v.collectForm.advertisers,
                            type: 'treeselect',
                            inputLabel: 'Advertisers',
                            options: this.advertiserOptions,
                            multiple: true,
                            placeholder: this.advertisers.length
                                ? 'Select advertisers'
                                : 'Authorize with RTB or select a token first',
                            invalid: 'At least one advertiser is required',
                            valid: 'Advertisers are valid',
                        },
                    ],
                },
                this.isDetails
                    ? null
                    : {
                          name: 'Data Collection Settings',
                          invalid: this.$v.$invalid,
                          inputs: [
                              {
                                  name: 'range',
                                  value: this.$v.workflowForm.historicalDateRange.$model,
                                  model: this.$v.workflowForm.historicalDateRange,
                                  type: 'range',
                                  inputLabel: 'Collect data for last (days)',
                                  min: 0,
                                  max: 365,
                              },
                          ],
                      },
            ].filter(Boolean)
        },
        isDetails() {
            return Boolean(this.$route.params.id)
        },
        cannotContinue() {
            return this.isDetails
        },
        dirty() {
            const collectFormDirty = filterDirty(this.collectForm, this.currentCollectForm)
            return Object.keys(collectFormDirty).length > 0
        },
        advertiserOptions() {
            return this.advertisers.map(el => ({
                id: el.hash,
                label: el.name,
            }))
        },
    },
    async created() {
        if (!this.isDetails) {
            const formSessionMap = JSON.parse(localStorage.getItem('formSessionIdMap'))
            const formSessionId = formSessionMap[this.$route.path].id

            this.$gtm.trackEvent({
                event: 'create_resource_start',
                action: 'create',
                value: formSessionId,
            })
        } else {
            this.fetchCollect()
        }
    },
    methods: {
        async fetchCollect() {
            this.$store.commit('loading/PROCESSING', `Fetching Collect Details...`)

            const {id} = this.$route.params
            const res = await this.axios.get(`${process.env.VUE_APP_NODE_API_HOST}/collect/RTBHouse/${id}`)

            this.collect = res.data.data

            this.collectForm = {
                name: this.collect.name,
                tokenId: this.collect.tokenRef ? this.collect.tokenRef.id : null,
                advertisers: this.collect.advertisers.map(({id}) => id),
                countConvention: this.countConventionOptions.find(el => el.id === this.collect.countConvention),
            }
            this.currentCollectForm = Object.assign({}, this.collectForm)
            this.$v.$reset()
            this.fetched = true

            this.$store.commit('loading/PROCESSED')
        },

        createCollect(step) {
            step.check()
            this.$forceUpdate()

            if (!this.$v.$invalid) {
                this.rtbError = {}

                const data = {
                    name: this.collectForm.name,
                    tokenId: this.collectForm.tokenId,
                    projectId: this.$store.state.project.active.id,
                    advertisers: this.collectForm.advertisers.map(_id => {
                        const {hash: id, name, currency} = this.advertisers.find(el => el.hash === _id)
                        return {id, name, currency}
                    }),
                    countConvention: this.collectForm.countConvention.id,
                    ...this.workflowForm,
                }

                this.$store.commit('loading/PROCESSING', `Creating Collect...`)
                this.axios
                    .post(`${process.env.VUE_APP_NODE_API_HOST}/collect/RTBHouse`, data)
                    .then(response => {
                        this.$store.commit('loading/PROCESSED')
                        this.rtbError = {}
                        this.resetForm()

                        const formSessionMap = JSON.parse(localStorage.getItem('formSessionIdMap'))
                        const formSessionId = formSessionMap[this.$route.path].id

                        this.$gtm.trackEvent({
                            event: 'create_resource_finish',
                            action: 'finish',
                            value: formSessionId,
                        })

                        formSessionMap[this.$route.path] = null
                        localStorage.setItem('formSessionIdMap', JSON.stringify(formSessionMap))

                        this.$projectRouter.push(this.redirectUrl)
                    })
                    .catch(exception => {
                        this.$store.commit('loading/PROCESSED')
                        this.rtbError.state = false
                        this.rtbError.message = exception.response.data.additionalInfo
                    })
            }
        },

        async updateCollect() {
            if (!this.$v.$invalid) {
                if (this.dirty) {
                    this.$store.commit('loading/PROCESSING', `Updating...`)
                    const {name, countConvention, advertisers} = filterDirty(this.collectForm, this.currentCollectForm)

                    const data = {
                        name: name ? name : undefined,
                        advertisers: advertisers
                            ? this.collectForm.advertisers.map(_id => {
                                  const {hash: id, name, currency} = this.advertisers.find(el => el.hash === _id)
                                  return {id, name, currency}
                              })
                            : undefined,
                        countConvention: countConvention ? this.collectForm.countConvention.id : undefined,
                    }

                    try {
                        await this.axios.post(
                            `${process.env.VUE_APP_NODE_API_HOST}/collect/RTBHouse/${this.$route.params.id}`,
                            data
                        )

                        this.rtbError = {}
                        this.currentCollectForm = Object.assign({}, this.collectForm)
                        this.$v.$reset()

                        this.$store.commit('loading/PROCESSED')
                    } catch (exception) {
                        this.$store.commit('loading/PROCESSED')
                        this.rtbError.state = false
                        this.rtbError.message = exception.response.data.data
                    }
                }
            }
        },

        async onTokenInput(tokenId) {
            this.$store.commit('loading/PROCESSING', `Fetching advertisers...`)
            try {
                if (!this.isDetails) {
                    this.collectForm.advertisers = []
                }

                const response = await this.axios.get(
                    `${process.env.VUE_APP_NODE_API_HOST}/collect/RTBHouse/advertisers?tokenId=${
                        tokenId ? tokenId : this.collectForm.tokenId
                    }`
                )
                this.advertisers = response.data.data

                this.$store.commit('loading/PROCESSED')
            } catch (exception) {
                this.$store.commit('loading/PROCESSED')
                this.rtbError.state = false
                this.rtbError.message = exception.response.data.data
            }
        },
    },
}
</script>
